import React, { useState } from "react";
import c from "./App.module.scss";
import "./Global.scss";
import Confetti from "react-confetti";
import cx from "classnames";

type Customer = "Okkenhaug" | "Mobile Follo" | "Mobile Ensjø" | "Brennes Auto";

const getActiveCustomer: () => Customer = () => {
  if (window.location.hostname.includes("brennes")) {
    return "Brennes Auto";
  } else if (window.location.hostname.includes("follo")) {
    return "Mobile Follo";
  } else if (window.location.hostname.includes("ensjo")) {
    return "Mobile Ensjø";
  }

  return "Okkenhaug";
};

const activeCustomer = getActiveCustomer();

function App() {
  const [customerName, setCustomerName] = useState<string>();
  const [carDescription, setCarDescription] = useState<string>();
  const [editMode, setEditMode] = useState(true);
  const [dealerLogoClickCount, setDealerLogoClickCount] = React.useState(0);

  if (dealerLogoClickCount === 5) {
    setEditMode((prevState) => !prevState);
    setDealerLogoClickCount(0);
  }

  return (
    <div
      className={cx(c.wrapper, {
        [c.mobile]: ["Mobile Follo", "Mobile Ensjø"].includes(activeCustomer),
        [c.brennes]: activeCustomer === "Brennes Auto",
      })}
    >
      {!editMode && <Confetti />}
      <div className={c.flexBox}>
        <div className={c.logoContainer}>
          <img
            src={getLogoUrl()}
            alt="logo"
            onClick={() => setDealerLogoClickCount(dealerLogoClickCount + 1)}
          />
        </div>
        <div className={c.text}>
          <div className={c.name}>{customerName}</div>
          <div className={c.car}>
            Gratulerer med ny {carDescription ? carDescription : "bil"}!
          </div>
        </div>
        {editMode && (
          <form>
            <div>
              <input
                value={customerName}
                autoCapitalize="words"
                type="name"
                placeholder="Skriv inn navn"
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </div>
            <div>
              <select
                name="bil"
                id="cars"
                value={carDescription}
                onChange={(e) => setCarDescription(e.target.value)}
              >
                {(activeCustomer === "Mobile Ensjø" ||
                  activeCustomer === "Mobile Follo") && (
                  <>
                    <option value="bil">Bil</option>
                    <option value="Hyundai">Hyundai</option>
                    <option value="bruktbil">Bruktbil</option>
                    <option value="Mazda">Mazda</option>
                    <option value="Citroën">Citroën</option>
                  </>
                )}
                {activeCustomer === "Brennes Auto" && (
                  <>
                    <option value="bil">Bil</option>
                    <option value="bruktbil">Bruktbil</option>
                    <option value="Hyundai">Hyundai</option>
                    <option value="Mazda">Mazda</option>
                    <option value="Citroën">Citroën</option>
                    <option value="Nissan">Nissan</option>
                    <option value="Suzuki">Suzuki</option>
                    <option value="BYD">BYD</option>
                  </>
                )}

                {activeCustomer === "Okkenhaug" && (
                  <>
                    <option value="bil">Bil</option>
                    <option value="Hyundai">Hyundai</option>
                    <option value="Subaru">Subaru</option>
                    <option value="bruktbil">Bruktbil</option>
                  </>
                )}
              </select>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                setEditMode(false);
              }}
            >
              Lagre
            </button>
          </form>
        )}
      </div>
      {carDescription === "Hyundai" && activeCustomer !== "Brennes Auto" && (
        <div className={c.soMePromotion}>
          <div className={c.firstParagraph}>
            Legg ut bilde av din nye Hyundai og bli med i trekning av gratis
            førstegangsservice!
          </div>
          <div className={c.secondParagraph}>
            Tagg <b>{getTagForActiveCustomer()}</b> på Instagram eller Facebook.
            Vi trekker fire vinnere hvert år.
          </div>
        </div>
      )}
      {activeCustomer === "Brennes Auto" && (
        <div className={c.soMePromotion}>
          <div className={c.firstParagraph}>
            Legg ut bilde av din nye bil og bli med i trekning av gratis
            førstegangsservice!
          </div>
          <div className={c.secondParagraph}>
            Tagg <b>"@Brennesauto"</b> på Instagram eller Facebook. Vi trekker
            fire vinnere hvert år.
          </div>
        </div>
      )}
    </div>
  );
}

export default App;

const getLogoUrl = () => {
  switch (activeCustomer) {
    case "Okkenhaug":
      return "https://res.cloudinary.com/hassel-software-as/image/upload/v1586270696/Forhandlerlogoer/okkenhaug_pylon.png";
    case "Brennes Auto":
      return "https://res.cloudinary.com/hassel-software-as/image/upload/v1684923017/BrennesAuto_Hvit.png";
    case "Mobile Follo":
    case "Mobile Ensjø":
      return "https://res.cloudinary.com/hassel-software-as/image/upload/v1587493769/Forhandlerlogoer/mobile_pylon.png";
  }
};

const getTagForActiveCustomer = () => {
  if (activeCustomer === "Mobile Ensjø") return "@mobileensjo";
  else if (activeCustomer === "Mobile Follo") return "@mobilefollo";
  else return "#Okkenhaugbil";
};
